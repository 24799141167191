import * as React from "react";
import { Link } from "gatsby";
import "./nav.css";

const Nav = () => (
  <nav className="navbar navbar-dark bg-dark navbar-expand-md">
    <div className="container-fluid">
      <button
        className="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#header-navbar"
        aria-controls="header-navbar"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span className="navbar-toggler-icon"></span>
      </button>

      <Link to="/" className="navbar-brand">
        Kris Jenkins
      </Link>

      <div className="collapse navbar-collapse" id="header-navbar">
        <ul className="navbar-nav">
          {[
            ["/", "Home"],
            ["/archive.html", "Archive"],
            ["/talks.html", "Talks"],
          ].map(([to, label]) => (
            <li key={to} className="nav-item">
              <Link to={to} className="nav-link" activeClassName="active">
                {label}
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </div>
  </nav>
);

export default Nav;
