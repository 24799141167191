import * as React from "react";

type TwitterFeedProps = {};

class TwitterFeed extends React.Component<TwitterFeedProps> {
  setScriptRef = (element: HTMLDivElement | null) => {
    if (element) {
      const script = document.createElement("script");
      script.src = "https://platform.twitter.com/widgets.js";
      element.appendChild(script);
    }
  };

  render() {
    return (
      <div ref={this.setScriptRef}>
        <a
          className="twitter-timeline"
          data-width="300"
          data-height="800"
          href="https://twitter.com/krisajenkins?ref_src=twsrc%5Etfw"
        >
          Tweets by krisajenkins
        </a>
      </div>
    );
  }
}

export default TwitterFeed;
