import { Link } from "gatsby";
import * as React from "react";

import Layout from "../components/layout";

type Markdown = {
  id: string;
  fileAbsolutePath: string;
  html: string;
  frontmatter: {
    title: string;
    formattedDate: string;
  };
};

type ArchiveProps = {
  pageContext: {
    postPaths: {
      path: string;
      node: Markdown;
    }[];
  };
};

type ArchiveEntryProps = {
  path: string;
  node: Markdown;
};

const ArchiveEntry: React.FC<ArchiveEntryProps> = ({ path, node }) => (
  <li>
    <Link to={path}>{node.frontmatter.title}</Link>
    &nbsp;
    <span>{node.frontmatter.formattedDate}</span>
  </li>
);

const Archive: React.FC<ArchiveProps> = ({ pageContext }) => {
  return (
    <Layout>
      <div className="page-header">
        <h3 className="mb-4">Kris Jenkins' Blog</h3>
      </div>
      <h3>Archive</h3>
      <ul>
        {pageContext.postPaths.map(({ path, node }) => (
          <ArchiveEntry key={node.id} path={path} node={node} />
        ))}
      </ul>
    </Layout>
  );
};

export default Archive;
