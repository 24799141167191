import * as React from "react";
import TwitterFeed from "../components/twitter";

import Nav from "./nav";
import "./layout.css";

type LayoutProps = {
  children: any;
};

const Layout: React.FC<LayoutProps> = ({ children }) => {
  return (
    <div>
      <Nav />

      <div className="container-fluid content">
        <div className="row">
          <div className="col-lg-6 offset-lg-1 col-md-8 col-12">
            <div>
              <div>{children}</div>
            </div>
          </div>

          <div className="offset-lg-1 col-md-4 d-md-block">
            <div className="twitterContainer">
              <TwitterFeed />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Layout;
